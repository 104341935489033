import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home.vue'

const routes = [
  {
path: '/*',
beforeEnter(to, from, next) {
// Put the full page URL including the protocol http(s) below
window.location.replace("https://ukzoneb.blwcmonline.com")
}},
  {
    path: '/',
    name: 'Home',
    // component: Home,
    beforeEnter(to, from, next) {
    // Put the full page URL including the protocol http(s) below
    window.location.replace("https://ukzoneb.blwcmonline.com")
}
  },
  {
    path: '/meeting',
    name: 'Home',
    // component: Home,
    beforeEnter(to, from, next) {
    // Put the full page URL including the protocol http(s) below
    window.location.replace("https://kingsconference.app/room/7zd6GZtRNXupRw/video")
}
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
